/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it
import "./src/assets/css/_global.css"
import "@fitplan/web-components/dist/esm/index.css";
import "@fitplan/studio-web-core/dist/esm/index.css";
import "./src/config.css"
import "./src/rackedstudio.css"
import "./src/rackedstudio.css"

import React from "react";
import { ErrorBoundary } from "react-error-boundary";
import { navigate } from "gatsby";
import { ROUTES } from "./src/utils/constant";

const ErrorFallback = ({ resetErrorBoundary }) => {
  // Call resetErrorBoundary() to reset the error boundary and retry the render.
  React.useEffect(() => {
    navigate(ROUTES.HOME, {
      state: {
        hasError: true,
      },
    });
    resetErrorBoundary();
  });
  return null;
};

export const wrapRootElement = ({ element }) => {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>{element}</ErrorBoundary>
  );
};
