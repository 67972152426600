export const ROUTES = {
  LOGIN: "/signin",
  RESET_PASSWORD: "/reset-password",
  ACCOUNT_SETTINGS: "/my-account",
  SIGNUP: "/signup",
  HOME: "/",
  MEMBER_SIGNUP: "/member-signup",
  PROSPECT_SIGNUP: "/prospect-signup",
  STUDIOS: "/studios",
  STUDIO_SIGNUP: "/studio-signup",
  PROSPECT_BOOK: "/prospect-book",
};

// Required to set text configuration in the studio-web-core
export const SITE_TEXT_CONFIG = {
  STUDIO_TYPE: "Racked Studio",
  STUDIO_TYPES: "Racked Studios",
  SITE_NAME: "Racked Studios",
  SHORT_SITE_NAME: "Racked",
  PRIVACY_URL: `https://www.rackedstudio.com/privacy-policy/`,
  TERMS_URL: `https://www.rackedstudio.com/terms-of-use/`,
  PROSPECT_CONSENT: `<p>By clicking "{{SUBMIT_BUTTON_TEXT}}" you consent to receive recurring, periodic promotional, personalized marketing text messages sent by automated technology from Racked concerning Racked Studios openings, offers, and updates to the phone number you provided. You also consent to receive marketing email messages and marketing phone calls to the phone number you provided. You can opt-out from text messaging by replying "STOP" to any text message. You can opt-out from emails by unsubscribing. You can request not to be called by phone if you receive a phone call. Message frequency varies. Message & data rates may apply. View the Racked Studios <a href="{{PRIVACY_POLICY_URL}}" target="_blank">Privacy Policy</a> and <a href="{{TERMS_URL}}" target="_blank">Terms of Use</a> for more information.</p>`,
  MAIL_URL: "newport@rackedstudio.com",
  STUDIO_DETAILS: {
    STUDIO_DESCRIPTION:
      "Personalized ice Bath, sauna, and breathwork experiences for mental and physical optimization. Click “Start Today” and Drop us your info to learn more about Racked Studio.",
  },
  STUDIO_FINDER: {
    HEADING: "Take control of your wellness.",
    DESCRIPTION: "Drop us your info to learn more about Racked Studio",
    SUCCESS_MESSAGE:
      "Thank you for your interest in Racked Studio. We'll be in touch soon. Follow @rackedstudio on Instagram to stay in the loop.",
    ERROR_MESSAGE:
      "We were unable to create your account at this time. Please ensure that all information is entered correctly and try again.",
  },
};

export const setHeaderHeight = (announcement: string) => {
  if (typeof window !== "undefined") {
    if (announcement) {
      const element: HTMLElement | null =
        document.getElementById("main-content");

      const styleElement = document.createElement("style");
      styleElement.type = "text/css";

      const mediaQuery =
        "@media screen and (max-width: 1024px) { #" +
        element?.id +
        " { padding-top: 147px; } }";

      styleElement.appendChild(document.createTextNode(mediaQuery));

      document.head.appendChild(styleElement);
    } else {
      const element = document.getElementById("main-content");

      const styleElement = document.createElement("style");
      styleElement.type = "text/css";

      const mediaQuery =
        "@media screen and (max-width: 1024px) { #" +
        element?.id +
        " { padding-top: 100px; } }";

      styleElement.appendChild(document.createTextNode(mediaQuery));
      document.head.appendChild(styleElement);
    }
  }
};
